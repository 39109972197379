import { FaTelegramPlane } from 'react-icons/fa';

import NavBar from '../Navbar';
import { RiTwitterXFill } from 'react-icons/ri';

const Hero = () => {

    return (
        <div className="overflow-hidden">
            <NavBar />
            <main className="flex-1 flex justify-center items-center overflow-hidden">
                <div className=" herobg flex items-center relative">
                    <div className="max-w-[1500px] w-full mx-auto px-4">
                        <div className="flex min-h-[calc(100vh-125px)]  flex-row-reverse md:!flex-col-reverse justify-center items-center gap-20 lg:gap-6 md:gap-4 text-white">
                            <div className="w-[50%]  lg:w-full xxl:px-4 lg:mt-[-10px] md:!mt-10">
                                <h1 className="font-Genty text-white text-[280px] xxl:text-[100px] lg:text-[100px] md:text-[80px] xs:text-[50px] text-center xxl:mb-2 mt-[-100px] md:mt-[-20px] md:hidden">
                                    peezy
                                </h1>

                                <div className="flex flex-nowrap xl:flex-wrap lg:!flex-nowrap justify-center items-center gap-3 mt-190 lg:mt-2 mb-10 md:mb-10 -mt-8">
                                    <a 
                                        href="https://app.pulsex.com/swap?outputCurrency=0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-black hover:bg-white/10 text-white flex justify-center items-center 
                                                    font-MonumentExtended pt-2 pb-1.5 w-1/2 xl:w-[75%] md:w-[160px] sm:w-[140px] 
                                                    rounded-full tracking-wide transition-all duration-300
                                                    border border-transparent hover:border-white/25"
                                    >
                                        <div className="flex items-center gap-2">
                                            <img
                                                src="/assets/images/PulseX_X.png"
                                                className="size-8 brightness-0 invert"
                                                alt="PulseChain"
                                            />
                                            <h3 className="text-nowrap md:text-xs py-2">BUY Peezy</h3>
                                        </div>
                                    </a>
                                </div>

                                {/* Social Links */}
                                <div className="mt-100 md:mt-[-20px] mb-[60px] md:mb-[80px] pb-16 md:pb-8">
                                    <div className="flex xl:flex-col items-center gap-5 md:pt-5">
                                        <div className="flex flex-wrap items-center gap-3 relative z-10 justify-center w-full">
                                            {/* Twitter */}
                                            <a 
                                                href="https://x.com/peezycoinpls"
                                                className="flex items-center justify-center rounded-full size-14 
                                                bg-gradient-to-r from-white/10 to-white/5 
                                                hover:from-white/20 hover:to-white/10 
                                                backdrop-blur-md border border-white/10 
                                                text-white hover:text-white transition-all duration-300"
                                            >
                                                <RiTwitterXFill className="size-[24px]" />
                                            </a>

                                            {/* Telegram */}
                                            <a 
                                                href="https://t.me/peezycoinpls"
                                                className="flex items-center justify-center rounded-full size-14 
                                                bg-gradient-to-r from-white/10 to-white/5 
                                                hover:from-white/20 hover:to-white/10 
                                                backdrop-blur-md border border-white/10 
                                                text-white hover:text-white transition-all duration-300"
                                            >
                                                <FaTelegramPlane className="size-[24px]" />
                                            </a>

                                            {/* PulseX */}
                                            <a 
                                                href="https://app.pulsex.com/swap?outputCurrency=0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center rounded-full size-14 
                                                bg-gradient-to-r from-white/10 to-white/5 
                                                hover:from-white/20 hover:to-white/10 
                                                backdrop-blur-md border border-white/10 
                                                text-white hover:text-white transition-all duration-300"
                                            >
                                                <img
                                                    src="/assets/images/PulseX_X.png"
                                                    alt="PulseX"
                                                    className="size-[28px] rounded-full brightness-0 invert"
                                                />
                                            </a>

                                            {/* Dexscreener */}
                                            <a 
                                                href="https://dexscreener.com/pulsechain/0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center rounded-full size-14 
                                                bg-gradient-to-r from-white/10 to-white/5 
                                                hover:from-white/20 hover:to-white/10 
                                                backdrop-blur-md border border-white/10 
                                                text-white hover:text-white transition-all duration-300"
                                            >
                                                <img
                                                    src="/assets/images/dexscreener(2).png"
                                                    alt="Dexscreener"
                                                    className="size-[50px]"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Text Content */}

                            {/* Image Section - Updated sizing and positioning */}
                            <div className="w-full xxl:px-4 ms-[-200px] pr-20 xxl:ms-auto lg:mx-auto relative flex items-center 
                                lg:justify-center md:justify-center sm:justify-center">
                                <img
                                    src="/assets/images/HighResPeezy_02.png"
                                    alt="Peezy"
                                    className="w-[120%] h-auto 
                                        xl:w-[120%] 
                                        lg:w-[90%] 
                                        md:w-[100%]
                                        sm:w-[90%]
                                        xs:w-[100%]
                                        bounce-animation z-10 relative
                                        lg:mx-auto md:mx-auto sm:mx-auto -mt-20 xxxl:mt-0
                                        md:mt-4"
                                    style={{
                                        maxWidth: 'none',
                                        '@media (max-width: 1024px)': {
                                            maxWidth: '100%',
                                            transform: 'translateX(0)',
                                            margin: '0 auto'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Hero
const DEXSCREENER_BASE_URL = 'https://api.dexscreener.com/latest/dex/tokens';
const MOG_TOKEN_ADDRESS = '0x698Cb3223D8eB1A3D9908e304775118DF4F81933';

async function fetchTokenData() {
    try {
        const response = await fetch(DEXSCREENER_BASE_URL + '/' + MOG_TOKEN_ADDRESS);
        const data = await response.json();

        // If no pairs exist yet, return default values
        if (!data.pairs || data.pairs.length === 0) {
            console.log('No pairs found yet - pair might be too new');
            return {
                marketCap: 0,
                liquidity: 0,
                volume24h: 0,
                priceUsd: 0,
                lastUpdated: new Date().toISOString(),
                pairCount: 0,
                pairs: []
            };
        }

        // Get the main pairs (PulseX and 9mm with any liquidity)
        const significantPairs = data.pairs.filter(pair => 
            pair.chainId === 'pulsechain' && 
            (pair.dexId === 'pulsex' || pair.dexId === '9mm')
            // Removed liquidity and market cap filters for new pairs
        );

        // Sort by liquidity to get the most liquid pairs
        significantPairs.sort((a, b) => 
            (parseFloat(b.liquidity?.usd) || 0) - (parseFloat(a.liquidity?.usd) || 0)
        );

        // Get market cap from the most liquid pair
        const mainPair = significantPairs[0];
        const marketCap = mainPair ? parseFloat(mainPair.marketCap || 0) : 0;
        const priceUsd = mainPair ? parseFloat(mainPair.priceUsd || 0) : 0;

        // Calculate totals from significant pairs
        let totalLiquidity = 0;
        let total24hVolume = 0;

        significantPairs.forEach(pair => {
            totalLiquidity += parseFloat(pair.liquidity?.usd || 0);
            total24hVolume += parseFloat(pair.volume?.h24 || 0);
        });

        return {
            marketCap,
            liquidity: totalLiquidity,
            volume24h: total24hVolume,
            priceUsd,
            lastUpdated: new Date().toISOString(),
            pairCount: significantPairs.length,
            pairs: significantPairs
        };
    } catch (error) {
        console.error('Error fetching MOG token data:', error);
        // Return default values on error
        return {
            marketCap: 0,
            liquidity: 0,
            volume24h: 0,
            priceUsd: 0,
            lastUpdated: new Date().toISOString(),
            pairCount: 0,
            pairs: []
        };
    }
}

export { fetchTokenData };
import { Routes, Route } from 'react-router-dom';
import PeezyPage from './pages/PeezyPage';
import MogCoinNew from './pages/MogCoinNew';
import PeezyCoinNew from './pages/PeezycoinNew';

function App() {
  return (
    <Routes>
      <Route path="/" element={<PeezyPage />} />
      <Route path="/demo" element={<MogCoinNew />} />
      <Route path="/demo2" element={<PeezyCoinNew />} />
    </Routes>
  );
}

export default App;

import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { useTokenData } from '../../hooks/useTokenData';
import { Link } from 'react-router-dom';

const Info = () => {
    const { marketCap, liquidity, volume24h, isLoading } = useTokenData();

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US').format(Math.round(num));
    };
    
    return (
        <div className="backdrop-blur-md bg-white/5 border-b border-white/10">
            <div className="max-w-[1500px] mx-auto px-4 py-4 md:py-2">
                <div className="flex items-center justify-between md:gap-2">
                    {/* Stats Section */}
                    <div className="flex gap-8 md:gap-4">
                        <div className="group">
                            <div className="text-white/60 font-MonumentExtended text-xs tracking-wider xs:text-[10px] xs:tracking-normal xs:text-nowrap">
                                MARKET CAP
                            </div>
                            <div className="text-white/80 font-MonumentExtended tracking-wider text-sm mt-0.5">
                                ${isLoading ? "Loading..." : formatNumber(marketCap)}
                            </div>
                        </div>

                        <div className="group">
                            <div className="text-white/60 font-MonumentExtended text-xs tracking-wider xs:text-[10px] xs:tracking-normal xs:text-nowrap">
                                VOLUME (24H)
                            </div>
                            <div className="text-white/80 font-MonumentExtended tracking-wider text-sm mt-0.5">
                                ${isLoading ? "Loading..." : formatNumber(volume24h)}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center gap-4 md:gap-2">
                        <button className="flex-1 bg-whitepeezy text-[#7B01FD] flex justify-center items-center font-MonumentExtended pt-2 pb-1.5 px-6 md:px-4 xs:px-3 xs:text-[10px] rounded-full hover:bg-whitepeezy tracking-wide text-sm md:text-xs">
                            <div className="flex items-center gap-2">
                                <img
                                    src="../assets/images/PulseX_X.png"
                                    className="size-6 md:size-4"
                                    alt="PulseChain"
                                />
                                <span>BUY NOW</span>
                            </div>
                        </button>
                        <Link
                            to="https://t.me/peezyfinance"
                            target="_blank"
                            aria-label='navigate to telegram'
                            rel="noopener noreferrer"
                            className="flex items-center justify-center rounded-full size-10 md:size-8 hover:bg-white/20 cursor-pointer z-50 bg-white/10 border border-white text-white hover:text-white/80 transition-all"
                        >
                            <FaTelegramPlane className="size-5 md:size-4" />
                            <span className="sr-only">Telegram</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info
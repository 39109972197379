import { Link } from 'react-router-dom';
import { useTokenData } from '../hooks/useTokenData';

export default function NavBar() {
    const { price, isLoading } = useTokenData();

    const formatPrice = (price) => {
        if (!price || isNaN(price) || price === 0) {
            return null;
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 8,
            maximumFractionDigits: 8
        }).format(price);
    };

    const formattedPrice = formatPrice(price);

    return (
        <div className="">
            <div className="max-w-[1500px] mx-auto px-6 py-4">
                <div className="flex items-center justify-between">
                    <Link to="/" className="group">
                        <span className="font-Genty text-6xl md:text-5xl text-white block transition-transform group-hover:scale-[1.02]">
                            peezy
                        </span>
                        {formattedPrice && (
                            <div className="flex items-center gap-2 mt-1">
                                <div className="w-1.5 h-1.5 rounded-full bg-green-400 animate-pulse"></div>
                                <span className="text-white/80 font-MonumentExtended text-sm tracking-wide">
                                    {isLoading ? "Loading..." : formattedPrice}
                                </span>
                            </div>
                        )}
                    </Link>
                </div>
            </div>
        </div>
    );
}

import { FaTelegramPlane } from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';

import Navbar from '../components/Navbar';
import Info from '../components/Info/Info.jsx';

const PeezyPage = () => {
    const [duration, setDuration] = useState('');

    useEffect(() => {
        const startDate = new Date('2024-12-04T03:00:00');
        
        const updateDuration = () => {
            const now = new Date();
            const diff = now - startDate;
            
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
            
            setDuration(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        };

        // Update immediately and then every second
        updateDuration();
        const interval = setInterval(updateDuration, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-[#4d9742]">
            {/* Header - Fixed at top */}
            <div className="fixed top-0 left-0 right-0 z-50">
                <Info />
                <Navbar />
            </div>

            {/* Main Content - With proper spacing from header and footer */}
            <main className="flex-1 flex justify-center items-center overflow-hidden">
                <div className="min-h-[calc(100vh-220px)] herobg flex items-center relative">
                    <div className="max-w-[1500px] w-full mx-auto px-4">
                        <div className="flex flex-row-reverse md:!flex-col-reverse justify-center items-center gap-20 lg:gap-6 md:gap-4 text-white">
                            <div className="w-[50%]  lg:w-full xxl:px-4 lg:mt-[-10px] md:!mt-0">
                                <h1 className="font-Genty text-white text-[280px] xxl:text-[100px] lg:text-[100px] md:text-[80px] xs:text-[50px] text-center xxl:mb-2 mt-[-100px] md:mt-[-20px] md:hidden">
                                    peezy
                                </h1>
                                <div className="flex flex-nowrap xl:flex-wrap lg:!flex-nowrap justify-center items-center gap-3 mt-100 lg:mt-2 mb-6 md:mb-6">
                                    <button className="bg-primarypeezy text-[#7B01FD] flex justify-center items-center font-MonumentExtended pt-2 pb-1.5 w-1/2 xl:w-[75%] md:w-[160px] sm:w-[140px] rounded-full hover:bg-primarypeezy tracking-wide">
                                        <div className="flex items-center gap-2">
                                            <img
                                                src="/assets/images/PulseX_X.png"
                                                className="size-8"
                                                alt="PulseChain"
                                            />
                                            <h3 className="text-nowrap md:text-xs py-2">BUY Peezy</h3>
                                        </div>
                                    </button>
                                </div>

                                {/* Social Links */}
                                <div className="mt-100 md:mt-[-20px] mb-[60px] md:mb-[80px]">
                                    <div className="flex xl:flex-col items-center gap-5 md:pt-5">
                                        <div className="flex flex-wrap items-center gap-3 relative z-10 justify-center w-full">
                                            {/* Twitter */}
                                            <a 
                                                href="https://x.com/peezycoinpls" 
                                                className="flex items-center justify-center rounded-full size-14 hover:bg-white/20 cursor-pointer z-50 bg-white/10 border border-white text-white hover:text-white/80 transition-all"
                                            >
                                                <RiTwitterXFill className="size-[24px]" />
                                            </a>

                                            {/* Telegram */}
                                            <a 
                                                href="https://t.me/peezycoinpls" 
                                                className="flex items-center justify-center rounded-full size-14 hover:bg-white/20 cursor-pointer z-50 bg-white/10 border border-white text-white hover:text-white/80 transition-all"
                                            >
                                                <FaTelegramPlane className="size-[24px]" />
                                            </a>

                                            {/* PulseX */}
                                            <a 
                                                href="https://app.pulsex.com/swap?outputCurrency=0x698cb3223d8eb1a3d9908e304775118df4f81933" 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center rounded-full size-14 hover:bg-white/20 cursor-pointer z-50 bg-white/10 border border-white text-white hover:text-white/80 transition-all"
                                            >
                                                <img
                                                    src="/assets/images/PulseX_X.png"
                                                    alt="PulseX"
                                                    className="size-[28px] rounded-full brightness-0 invert"
                                                />
                                            </a>

                                            {/* Dexscreener */}
                                            <a 
                                                href="https://dexscreener.com/pulsechain/0x698cb3223d8eb1a3d9908e304775118df4f81933" 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center rounded-full size-14 hover:bg-white/20 cursor-pointer z-50 bg-white/10 border border-white text-white hover:text-white/80 transition-all"
                                            >
                                                <img
                                                    src="/assets/images/dexscreener(2).png"
                                                    alt="Dexscreener"
                                                    className="size-[50px]"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Text Content */}

                            {/* Image Section - Updated sizing and positioning */}
                            <div className="w-full xxl:px-4 ms-[-200px] pr-20 xxl:ms-auto lg:mx-auto relative flex items-center 
                                lg:justify-center md:justify-center sm:justify-center">
                                <img
                                    src="/assets/images/HighResPeezy_02.png"
                                    alt="Peezy"
                                    className="w-[120%] h-auto 
                                        xl:w-[120%] 
                                        lg:w-[90%] 
                                        md:w-[100%]
                                        sm:w-[90%]
                                        xs:w-[100%]
                                        bounce-animation z-10 relative
                                        lg:mx-auto md:mx-auto sm:mx-auto
                                        md:mt-4"
                                    style={{
                                        maxWidth: 'none',
                                        '@media (max-width: 1024px)': {
                                            maxWidth: '100%',
                                            transform: 'translateX(0)',
                                            margin: '0 auto'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer - Fixed at bottom */}
            <div className="fixed bottom-0 left-0 right-0 z-40">
                <div className="backdrop-blur-md bg-white/5 border-t border-white/10">
                    <div className="max-w-[1500px] mx-auto px-4 py-4 md:py-2">
                        <div className="flex flex-col gap-2">
                            {/* Timer Display */}
                            <div className="flex items-center justify-center w-full">
                                <a 
                                    href="https://t.me/peezycoinpls?videochat"
                                    target="_blank"
                                    rel="noopener noreferrer" 
                                    className="text-white/80 font-MonumentExtended text-sm md:text-xs hover:text-white transition-colors cursor-pointer"
                                >
                                    Telegram VC has been live for {duration}
                                </a>
                            </div>
                            
                            {/* Existing footer content */}
                            <div className="flex items-center justify-between lg:flex-row lg:gap-2">
                                {/* Contract Address - Modified for mobile */}
                                <div className="flex items-center gap-4 lg:gap-1 lg:flex-row">
                                    <span className="text-white/60 font-MonumentExtended text-sm md:text-xs">
                                        CA:
                                    </span>
                                    <div className="flex items-center gap-2 bg-white/5 rounded-lg px-4 py-2 md:py-1 border border-white/10">
                                        <span className="text-white/80 font-MonumentExtended tracking-wider text-sm md:text-xs">
                                            0x69..1933
                                        </span>
                                        <button
                                            className="p-2 md:p-1 rounded-lg hover:bg-white/10 transition-all group"
                                            onClick={() => navigator.clipboard.writeText('0x698cb3223d8eb1a3d9908e304775118df4f81933')}
                                            title="Copy to clipboard"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                className="w-4 h-4 md:w-3 md:h-3 text-white/60 group-hover:text-white transition-colors"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                {/* Network Info */}
                                <div className="flex items-center gap-4 md:gap-2">
                                    <div className="flex items-center gap-2 md:gap-1">
                                        <div className="w-2 h-2 rounded-full bg-[#4d9742]"></div>
                                        <span className="text-gray-600 font-MonumentExtended text-sm md:text-xs md:hidden">
                                            Live on Mainnet
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 md:gap-1">
                                        <img
                                            src="/assets/images/Pulsechain.png"
                                            alt="PulseChain"
                                            className="w-6 h-6 md:w-4 md:h-4"
                                        />
                                        <span className="text-gray-600 font-MonumentExtended text-sm md:text-xs md:hidden">
                                            PulseChain
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Adjust main content bottom margin to account for smaller footer */}
            {/* Remove this overflow property if the content exceeds the container's height */}
            <style jsx>{`
            
            body {
              overflow: hidden;
                }
            
                @media (max-width: 768px) {
              
                    // main {
                    //     margin-bottom: 60px !important;
                    // }
                }
            `}</style>
        </div>
    );
}

export default PeezyPage;

import React, { Suspense, lazy } from 'react';
import InfoSkeleton from '../components/Skeleton/InfoSkeleton';
import CarSkeleton from '../components/Skeleton/CarSkeleton';
import HeroSkeleton from '../components/Skeleton/HeroSkeleton';
import AboutSkeleton from '../components/Skeleton/AboutSkeleton';
import HowtobuySkeleton from '../components/Skeleton/HowtobuySkeleton';
import BridgeSkeleton from '../components/Skeleton/BridgeSkeleton';
import TradeSkeleton from '../components/Skeleton/TradeSkeleton';
import SupplySkeleton from '../components/Skeleton/SupplySkeleton';
import Hero from '../components/MogCoinNew/Hero';

// Lazy loading all components
const Info = lazy(() => import("../components/MogCoinNew/Info"));
const Car = lazy(() => import("../components/MogCoinNew/Car"));
const About = lazy(() => import("../components/MogCoinNew/About"));
const HowToBuy = lazy(() => import("../components/MogCoinNew/HowToBuy"));
const Bridge = lazy(() => import("../components/MogCoinNew/Bridge"));
const Trade = lazy(() => import("../components/MogCoinNew/Trade"));
const Supply = lazy(() => import("../components/MogCoinNew/Supply"));

export default function PeezyCoinNew() {
    return (
        <div className='overflow-hidden'>
            <div
                className="bg-fixed object-cover bg-cover overflow-hidden w-screen min-h-screen relative md:bg-center"
                style={{
                    backgroundImage: "url('assets/images/peezybg.png')",
                    backgroundColor: "#000",
                    maxWidth: '100vw',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    '@media (max-width: 768px)': {
                        backgroundSize: '100% 100vh',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center top'
                    }
                }}
            >
                <div className="max-w-full overflow-x-hidden">
                    {/* <Suspense fallback={<div className='text-center h-screen flex justify-center items-center'>Loading Home...</div>}>
                    <Info />
                </Suspense> */}
                    <Suspense fallback={<InfoSkeleton />}>
                        <Info />
                    </Suspense>

                    <Suspense fallback={<HeroSkeleton />}>
                        <Hero />
                    </Suspense>

                    <Suspense fallback={<CarSkeleton />}>
                        <Car />
                    </Suspense>

                    <Suspense fallback={<AboutSkeleton />}>
                        <About />
                    </Suspense>

                    <Suspense fallback={<HowtobuySkeleton />}>
                        <HowToBuy />
                    </Suspense>

                    <Suspense fallback={<BridgeSkeleton />}>
                        <Bridge />
                    </Suspense>

                    <Suspense fallback={<TradeSkeleton />}>
                        <Trade />
                    </Suspense>

                    <Suspense fallback={<SupplySkeleton />}>
                        <Supply />
                    </Suspense>
                </div>
            </div>
        </div>
    );
}
